export const regExp = {
  positiveInteger: /^\+?[1-9][0-9]*$/,
  int: /^[\d]+$/,
  intTwoDot: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
  // eslint-disable-next-line no-useless-escape
  userName: /^[a-zA-Z][a-zA-Z0-9_\@\$\.]+$/,
  tel: /^1[23456789]\d{9}$/,
};
export function getByteLength(val) {
  if (!val) return 0;
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    let a = val.charAt(i);
    // eslint-disable-next-line
    if (
      a.match(/[^\x00-\xff]/gi) != null ||
      a.match(/[A-Z]/g) != null ||
      a.match(/\./g) != null
    ) {
      len += 2;
    } else {
      len += 1;
    }
  }
  return len;
}

export function getSellerCode() {
  let url = location.href;
  if (url.includes("sellerCode")) {
    let sellerCode = url.match(/\/sellerCode\/(.*)/)?.[1];
    if (sellerCode) {
      return sellerCode;
    }
  }
  return "";
}
