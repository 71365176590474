<template>
  <el-dialog
    title="注册协议"
    :visible.sync="dialogVisible"
    width="1080px"
    top="4vh"
    :append-to-body="true"
  >
    <div class="protocol" style="height: 700px; overflow-y: auto">
      <p class="title">{{ login.registerAgreementBrand }}用户服务协议</p>
      <p>一、欢迎使用{{ login.registerAgreementBrand }}</p>
      <p class="protocol-detail">
        感谢您使用我们的产品和服务(下称“服务”)。服务由合肥亿起说网络技术有限公司(下称“{{
          login.registerAgreementBrand
        }}”)提供，公司地址为安徽省合肥市滨湖区蓝山 CBD2118。
      </p>
      <p class="protocol-detail">
        用户确认本服务协议后，本服务协议即在用户和{{
          login.registerAgreementBrand
        }}网之间产生法律效力，请用户务必在注册之前认真阅读本服务协议内容，如有任何疑问可向平台客服咨询，无论用户事实上是否在注册之前认真阅读过本
      </p>
      <p class="protocol-detail">
        服务协议内容，只要用户点击“确认”按钮并按照平台注册程序注册成功，其行为将被视为同意并签署本服务协议，本服务协议不涉及平台中用户之间产生的任何法律关系和法律纠纷。
      </p>
      <p>二、接受条款</p>
      <p class="protocol-detail">
        1、本协议内容包括协议正文及所有{{
          login.registerAgreementBrand
        }}已经发布或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
      </p>
      <p class="protocol-detail">
        2、以任何方式进入{{
          login.registerAgreementBrand
        }}并使用服务即表示您已充分阅读、理解并同意接受本协议的条款和条件(以下合称“条款”)。
      </p>
      <p class="protocol-detail">
        3、{{
          login.registerAgreementBrand
        }}有权根据业务需要酌情修订“条款”，并以网站公告的形式进行更新，不再单独通知予您。经修订的“条款”一经在{{
          login.registerAgreementBrand
        }}公布，即产生效力。如您不同意相关修订，请您立即停止使用“服务”。如您继续使用“服务”，则将视为您已接受经修订的“条款”，当您与{{
          login.registerAgreementBrand
        }}发生争议时，应以最新的“条款”为准。
      </p>
      <p>三、用户注册</p>
      <p class="protocol-detail">
        1、为了使用{{
          login.registerAgreementBrand
        }}提供的服务，用户需要创建一个{{ login.registerAgreementBrand }}帐户。{{
          login.registerAgreementBrand
        }}的账户注册方式为：手机号码注册。
      </p>
      <p class="protocol-detail">
        2、注册用户具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人，或者是具有合法经营资格的实体组织。无民事行为能力人、限制民事行为能力人以及无经营或特定经营资格的组织不当注册为{{
          login.registerAgreementBrand
        }}网用户或超过其民事权利或行为能力范围而从事交易的，其与{{
          login.registerAgreementBrand
        }}网之间的服务协议自动无效，一经发现，{{
          login.registerAgreementBrand
        }}网有权立即注销该用户，并追究其使用{{
          login.registerAgreementBrand
        }}网中一切“服务”的任何法律责任。如您代表一家公司或其他法律主体在{{
          login.registerAgreementBrand
        }}网注册登记，则您声明和保证，您有权使该公司或该法律主体受本协议“条款”的约束。
      </p>
      <p class="protocol-detail">
        3、用户注册是指用户登陆{{
          login.registerAgreementBrand
        }}网后，保证诸如电子邮件地址、联系电话、联系地址、邮政编码和所发布内容的有效性、安全性、真实性，保证{{
          login.registerAgreementBrand
        }}网及所有用户可以通过上述联系方式与其进行联系。
      </p>
      <p class="protocol-detail">
        同时，企业用户也有义务在相关资料实际变更时及时更新有关注册资料并保证不以他人或其他组织的资料在{{
          login.registerAgreementBrand
        }}网进行注册或认证。
      </p>
      <p>四、服务使用</p>
      <p class="protocol-detail">用户同意：</p>
      <p class="protocol-detail">（1）提供及时、详尽及准确的用户资料。</p>
      <p class="protocol-detail">
        （2）不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。
      </p>
      <p class="protocol-detail">
        （3）用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等有关计算机及互联网规定的法律和法规、实施办法。在任何情况下，{{
          login.registerAgreementBrand
        }}网合理地认为用户的行为可能违反上述法律、法规，{{
          login.registerAgreementBrand
        }}网可以在任何时候，不经事先通知终止向该用户提供服务，不需对用户或第三方负责。
      </p>
      <p>五、用户的权利和义务</p>
      <p class="protocol-detail">
        1、用户有权利拥有自己在{{
          login.registerAgreementBrand
        }}网的用户名及密码，并有权利使用自己的用户名及密码随时登陆{{
          login.registerAgreementBrand
        }}网进行交易。用户不得以任何形式擅自转让或授权他人使用自己的{{
          login.registerAgreementBrand
        }}网用户名。用户须自行负责自己的用户账号和密码，且须对在用户账号密码下发生的所有活动（包括但不限于发布需求信息、网上点击同意各类协议、规则、参与需求投标等）承担责任。用户有权根据需要更改登录和账户提现密码。
      </p>
      <p class="protocol-detail">
        2、因用户的过错导致的任何损失由用户自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，用户使用的计算机被他人侵入。
      </p>
      <p class="protocol-detail">
        2、用户有权根据本协议的规定以及{{
          login.registerAgreementBrand
        }}网上发布的相关规则利用{{ login.registerAgreementBrand }}查询或使用{{
          login.registerAgreementBrand
        }}上所提供的相关服务，并可参加{{
          login.registerAgreementBrand
        }}网发布的有关活动以及有权享受{{
          login.registerAgreementBrand
        }}网提供的其它的有关信息服务及活动。
      </p>
      <p class="protocol-detail">
        3、用户在{{
          login.registerAgreementBrand
        }}网上交易过程中如与其他用户因交易产生纠纷，可以请{{
          login.registerAgreementBrand
        }}网从中予以协调，用户如发现其他用户有违法或违反本服务协议的行为可以向{{
          login.registerAgreementBrand
        }}网进行反映并要求处理。如用户因网上交易与其他用户产生诉讼的，用户有权通过司法部门要求{{
          login.registerAgreementBrand
        }}网提供相关资料。
      </p>
      <p class="protocol-detail">
        4、用户应当保证在使用{{
          login.registerAgreementBrand
        }}网进行交易过程中遵守诚实信用的原则，不在交易过程中采取任何不正当行为。
      </p>
      <p class="protocol-detail">
        5、用户不得将账户以任何形式给他人使用，包括但不限于出租和售卖等形式。违反这一规则的，{{
          login.registerAgreementBrand
        }}有权在不通知用户的前提下，封禁账户。
      </p>
      <p class="protocol-detail">
        6、用户承诺其在使用{{
          login.registerAgreementBrand
        }}网时实施的所有行为均遵守国家相关的法律、法规和{{
          login.registerAgreementBrand
        }}网的相关规定及协议和声明，以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以其名义独立承担所有相应的法律责任。
      </p>
      <p class="protocol-detail">
        7、用户同意不对{{
          login.registerAgreementBrand
        }}网上任何数据作商业性利用或恶意更改，包括但不限于在未经{{
          login.registerAgreementBrand
        }}网事先书面批准的情况下，以复制、传播等方式使用在{{
          login.registerAgreementBrand
        }}网上展示的任何资料和数据。
      </p>
      <p class="protocol-detail">
        8、您有权向{{ login.registerAgreementBrand }}要求注销您的账户，经{{
          login.registerAgreementBrand
        }}审核同意的，{{
          login.registerAgreementBrand
        }}将注销您的账户，届时，您与{{
          login.registerAgreementBrand
        }}基于本协议的合同关系即终止。您的账户被注销后，{{
          login.registerAgreementBrand
        }}没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
      </p>
      <p class="protocol-detail">
        9、您理解并同意，您与{{
          login.registerAgreementBrand
        }}的合同关系终止后：
      </p>
      <p class="protocol-detail">
        a){{ login.registerAgreementBrand }}有权继续保存您的资料。
      </p>
      <p class="protocol-detail">
        b)您在使用服务期间存在违法行为或违反本协议和/或规则的行为的，{{
          login.registerAgreementBrand
        }}仍可依据本协议向您主张权利。
      </p>
      <p class="protocol-detail">
        c）您在使用服务期间因使用服务与其他用户之间发生的关系，不因本协议的终止而终止，其他用户仍有权向您主张权利，您应继续按您的承诺履行义务。
      </p>
      <p class="protocol-detail">
        10、恶意攻击和爬取{{
          login.registerAgreementBrand
        }}数据将被视为非正常用户，非正常用户行为将被永久封禁，{{
          login.registerAgreementBrand
        }}不做任何解释和承担任何连带责任。请您务必遵守本款条约。
      </p>
      <p>六、用户发布的内容</p>
      <p class="protocol-detail">
        {{
          login.registerAgreementBrand
        }}的某些服务允许用户提交内容。用户保留对该内容持有的任何知识产权的所有权。
      </p>
      <p class="protocol-detail">
        某些服务可能会向用户提供一些方法，用于访问和删除用户提供给该服务的内容。此外，{{
          login.registerAgreementBrand
        }}某些服务中的条款或设置还会缩小{{
          login.registerAgreementBrand
        }}对提交至相关服务的内容的使用范围。对于用户提交至{{
          login.registerAgreementBrand
        }}服务的任何内容，请确保用户拥有向{{
          login.registerAgreementBrand
        }}授予此许可的必要权利。
      </p>
      <p>七、{{ login.registerAgreementBrand }}的权利和义务</p>
      <p class="protocol-detail">
        1、{{
          login.registerAgreementBrand
        }}有义务在现有技术水平的基础上努力确保整个网上服务的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行。
      </p>
      <p class="protocol-detail">
        2、对用户在使用{{
          login.registerAgreementBrand
        }}网中所遇到的问题及反映的情况，{{
          login.registerAgreementBrand
        }}会及时做出回复。
      </p>
      <p class="protocol-detail">
        3、因网上交易的特殊性，{{
          login.registerAgreementBrand
        }}网没有义务对所有用户提供的服务以及与交易有关的其它服务和事项进行事先任何全部审查。
      </p>
      <p class="protocol-detail">
        4、企业用户在{{
          login.registerAgreementBrand
        }}网交易过程中如与其它企业或个人用户因交易产生纠纷，可请求{{
          login.registerAgreementBrand
        }}从中予以协调。如因此而产生诉讼的，用户可通过司法部门或行政部门依照法定程序要求{{
          login.registerAgreementBrand
        }}提供相关数据，{{
          login.registerAgreementBrand
        }}会配合提供有关资料，但不承担由此而产生的一切相关责任；{{
          login.registerAgreementBrand
        }}网有权对用户发布的全部内容及交易行为进行查阅，发现其发布内容或交易行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接做出删除等处理。
      </p>
      <p>八、修改和终止服务</p>
      <p class="protocol-detail">
        1、{{ login.registerAgreementBrand }}始终在不断更改和改进{{
          login.registerAgreementBrand
        }}的服务。{{
          login.registerAgreementBrand
        }}可能会增加或删除功能，也可能暂停或彻底停止某项服务。{{
          login.registerAgreementBrand
        }}将在中断或终止服务时通知用户。
      </p>
      <p class="protocol-detail">
        2、如{{
          login.registerAgreementBrand
        }}网通过用户提供的信息与用户联系时，发现用户在注册时填写的注册信息已不存在或无法找到其电子邮箱地址的，经{{
          login.registerAgreementBrand
        }}以其它联系方式通知用户更改后而用户仍未能尽快提供新的电子邮箱地址及注册信息的，{{
          login.registerAgreementBrand
        }}网有权终止向该用户提供服务。
      </p>
      <p class="protocol-detail">
        3、在用户提供的信息涉嫌造假、用户违反相关法律规定、用户违反本服务协议相关规定时，{{
          login.registerAgreementBrand
        }}网有权中断或终止向该用户提供服务，其中如有给个人用户造成重大人身、财产损失者，{{
          login.registerAgreementBrand
        }}网将向有关执法部门反映，并追究其法律责任。但如该用户在被{{
          login.registerAgreementBrand
        }}中断或终止提供服务后，再一次直接或间接或以他人名义注册为{{
          login.registerAgreementBrand
        }}用户的，{{
          login.registerAgreementBrand
        }}有权再次单方面终止向该用户提供服务。
      </p>
      <p class="protocol-detail">
        4、我们认为您拥有自己数据的所有权并保留对此类数据的访问权限，这一点非常重要。如果我们停止某项服务，在合理可能的情况下，我们会向用户发出合理的提前通知，并让用户有机会将信息从服务中转移出来。
      </p>
      <p>九、免责条款</p>
      <p class="protocol-detail">
        {{
          login.registerAgreementBrand
        }}网旨在建立一个网上科技服务共享平台，所发布的信息虽经初步筛选，但不能保证其所述是完全真实可靠的，信息持有者或提供者应依法对其提供的信息承担全部责任。
      </p>
      <p class="protocol-detail">
        {{ login.registerAgreementBrand }}对任何在{{
          login.registerAgreementBrand
        }}发布信息或使用信息所带来的风险不承担任何责任。限于数据更新机制等原因，{{
          login.registerAgreementBrand
        }}无法保证搜索结果、服务信息等数据的实时更新，数据准确性仅供参考。访问和使用{{
          login.registerAgreementBrand
        }}者应自行判断相关信息。
      </p>
      <p class="protocol-detail">
        对用户使用网站、与{{
          login.registerAgreementBrand
        }}相关的任何内容、服务或其它链接至{{
          login.registerAgreementBrand
        }}的站点、内容均不作直接、间接、法定、约定的保证。无论在任何原因下（包括但不限于疏忽原因），对用户或任何人通过使用{{
          login.registerAgreementBrand
        }}的信息或由{{ login.registerAgreementBrand }}链接的信息，或其他与{{
          login.registerAgreementBrand
        }}链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）。
      </p>
      <p class="protocol-detail">
        {{
          login.registerAgreementBrand
        }}保留随时修改此用户服务协议内容及其它相关文件的权利，本用户服务协议、其它相关文件以及与其相关的内容如有变动恕不另行通知。
      </p>
      <p>十、解释权</p>
      <p class="protocol-detail">
        本注册协议的解释权归合肥亿起说网络技术有限公司所有。如果其中有任何条款与国家的有关法律相抵触，则以国家法律的明文规定为准。
      </p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "register-agreement-dialog",
  inject: ["login"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
};
</script>

<style scoped></style>
