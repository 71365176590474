const cache = {};

export default class OfficialQrcode {
  constructor(client, scene, callback) {
    this.client = client;
    this.scene = scene;
    this.callback = callback;
    this.timer = null;
  }

  async create() {
    const cacheQrcode = cache?.[this.scene] || null;
    const currTimestamp = Math.floor(Date.now() / 1000);
    if (cacheQrcode && cacheQrcode.expire_timestamp - 60 > currTimestamp) {
      this.loopStatus();
      return { ...cacheQrcode };
    }

    const result = await this.client.createWechatOfficialQrcode(this.scene);
    cache[this.scene] = {
      ...result,
      expire_timestamp: Math.floor(Date.now() / 1000) + result.expire,
    };

    this.loopStatus();
    return {
      ...cache[this.scene],
    };
  }

  async loopStatus() {
    const qrcode = cache[this.scene];

    let result;
    try {
      result = await this.client.getWechatOfficialQrcode(qrcode.id);
    } catch (e) {
      delete cache[this.scene];
      this.callback(e);
      return;
    }

    if (result.status === "待扫码") {
      if (this.timer === 0) {
        return;
      }

      this.timer = setTimeout(() => {
        this.loopStatus();
      }, 2000);
      return;
    }

    delete cache[this.scene];
    this.callback(null, qrcode);
  }

  destroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = 0;
  }
}
