<template>
  <div class="am-login__login">
    <div class="am-login__login__header">账号注册</div>

    <div class="am-login__login__body">
      <el-form
        ref="form"
        class="am-login__login-form"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="phone">
          <el-input v-model.trim="form.phone" placeholder="请输入手机号">
          </el-input>
        </el-form-item>

        <el-form-item prop="nickname">
          <el-input v-model.trim="form.nickname" placeholder="请输入昵称">
          </el-input>
        </el-form-item>

        <el-form-item prop="captcha">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-input v-model.trim="form.captcha" placeholder="请输入6位验证码">
            </el-input>

            <captcha-button
              style="padding: 0 12px"
              :phone-method="getPhone"
              scene="注册"
              type="text"
              >点击获取
            </captcha-button>
          </div>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="form.password"
            type="password"
            placeholder="请输入登录密码"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <el-button
        style="width: 100%"
        type="primary"
        :loading="loading"
        @click="onClick()"
        >注册
      </el-button>

      <div class="am-login__login__tools" v-if="login.goLogin">
        <span>已有账号，</span>
        <el-link
          type="primary"
          :underline="false"
          class="am-login__login__tools__link"
          @click="$emit('go', 'LoginMain')"
          >去登陆
        </el-link>
      </div>
    </div>

    <div v-if="login.registerAgreement" class="am-login__login__footer">
      <el-checkbox v-model="form.checked">我已认真阅读并同意</el-checkbox>
      <el-link
        type="info"
        :underline="false"
        style="vertical-align: baseline"
        @click="registerAgreementDialogVisible = true"
        >《{{ login.registerAgreementBrand }}用户服务协议》</el-link
      >
    </div>

    <register-agreement-dialog :visible.sync="registerAgreementDialogVisible" />
  </div>
</template>

<script>
import CaptchaButton from "./captcha-button";
import { validatorPhone } from "@/utils/common";
import RegisterAgreementDialog from "./register-agreement-dialog";

export default {
  name: "register",
  inject: ["login"],
  components: { CaptchaButton, RegisterAgreementDialog },
  props: {},
  data() {
    this.rules = {
      phone: [
        { required: true, message: "请输入手机号", trigger: ["blur"] },
        { validator: validatorPhone, trigger: ["blur"] },
      ],
      captcha: [{ required: true, message: "请输入验证码", trigger: ["blur"] }],
      password: [{ required: true, message: "请输入密码", trigger: ["blur"] }],
    };

    return {
      loading: false,
      registerAgreementDialogVisible: false,
      form: {
        phone: null,
        nickname: null,
        captcha: null,
        password: null,
        checked: false,
      },
    };
  },
  created() {
    this.$aming.loginDialog.getVm().$on("setPhone", (phone) => {
      this.form.phone = phone;
    });
  },
  methods: {
    getPhone() {
      return new Promise((resolve) => {
        this.$refs.form.validateField("phone", (error) => {
          if (error) {
            resolve(null);
          }

          resolve(this.form.phone);
        });
      });
    },

    // 点击注册
    onClick() {
      if (this.login.registerAgreement && !this.form.checked) {
        this.$message.warning("请先同意用户协议");
        return;
      }

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.loading = true;
        try {
          await this.$aming.client.register(
            this.form.phone,
            this.form.password,
            this.form.captcha,
            this.form.nickname
          );
        } catch (e) {
          this.$message.error(e.message);
          return;
        } finally {
          this.loading = false;
        }

        this.$message.success("注册成功");
        this.$aming.loginDialog.getVm().$emit("registerSuccess");
        this.$aming.loginDialog.close();
        // this.$emit("go", "LoginMain");
      });
    },
  },
  activated() {
    debugger;
  },
};
</script>

<style lang="scss" scoped>
.am-login__login {
  position: relative;
  padding: 40px;

  .am-login__login__header {
    font-size: 28px;
    color: #303133;
  }

  .am-login__login__body {
    padding: 40px 0;
  }
}

.am-login__login__icon {
  font-size: 16px;
  padding-left: 4px;
}

.am-login__login__tools {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #909399;
  line-height: 1.5;

  .am-login__login__tools__link {
    vertical-align: baseline;
  }
}

.am-login__login-form {
  &::v-deep(.el-form-item) {
    border-bottom: 1px solid #dcdfe6;
  }

  &::v-deep(.el-input__inner) {
    border: none;
    padding: 0;
  }
}

.am-login__login__footer {
  background-color: #f5f7fa;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 40px;
}
</style>
