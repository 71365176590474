var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'am-login__login': true,
    'am-login__login__scan-login': _vm.login.scanLogin,
  }},[(_vm.login.scanLogin)?_c('div',{class:{
      'am-login__login-mode-wrapper': true,
      'am-login__login-mode-pc': _vm.isQrcode,
    },attrs:{"title":_vm.isQrcode ? '账号登录' : '扫码登录'},on:{"click":function($event){_vm.isQrcode = !_vm.isQrcode}}},[_c('div',{staticClass:"am-login__login-mode-icon"})]):_vm._e(),_c('div',{staticClass:"am-login__login__header"},[_vm._v(" "+_vm._s(_vm.isQrcode ? "微信" : "账号")+"登录 ")]),_c('div',{staticClass:"am-login__login__body"},[(!_vm.isQrcode)?[_c('login-account',{on:{"login":function($event){return _vm.$emit('login', $event)},"go":function($event){return _vm.$emit('go', $event)}}})]:[_c('login-qrcode',{on:{"login":function($event){return _vm.$emit('login', $event)},"go":function($event){return _vm.$emit('go', $event)}}})]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }