import Vue from "vue";
import VueRouter from "vue-router";

const router = [
  {
    path: "",
    component: () => import("@/view/refund/index.vue"),
  },
  {
    path: "*",
    component: () => import("@/view/refund/index.vue"),
  },
];
Vue.use(VueRouter);
export default new VueRouter({
  routes: router,
  mode: "history",
});
