<template>
  <simple-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    width="440px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="am-login-dialog"
    @open="onDialogOpen"
    :show-close="showClose"
  >
    <div class="am-login-dialog__body">
      <login
        style="background-color: #fff; border-radius: 20px; height: 100%"
        @login="onLogin"
      />
    </div>
  </simple-dialog>
</template>

<script>
import Login from "../../login/src/login";
import SimpleDialog from "../../simple-dialog/src/simple-dialog";

export default {
  name: "am-login-dialog",
  components: { SimpleDialog, Login },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default() {
        return this.$aming?.login?.showClose ?? true;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },

  methods: {
    onLogin(tokens) {
      this.$aming.auth.init();
      this.dialogVisible = false;
      this.$emit("login", tokens);
    },

    // 加载横幅
    async onDialogOpen() {},
  },
};
</script>

<style lang="scss" scoped>
.am-login-dialog::v-deep(.el-dialog) {
  background-color: transparent;
  border-radius: 20px;
  transition: 0.1s;
}

.am-login-dialog::v-deep(.el-dialog__body) {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  padding: 30px 20px !important;
}

.am-login-dialog__body {
  margin: -30px -20px;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
