import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
import "@/assets/scss/index.scss";
import router from "@/router/index.js";
import store from "@/store/index.js";
import "@/config/global-components.js";
import "@/config/filter.js";

import ElementUI from "element-ui";
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
