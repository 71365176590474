import LoginDialog from "./src/login-dialog";

let instance = null;
const initInstance = (Vue) => {
  const Constructor = Vue.extend(LoginDialog);
  instance = new Constructor({
    el: document.createElement("div"),
  });
};

/* istanbul ignore next */
LoginDialog.install = function (Vue) {
  Vue.component(LoginDialog.name, LoginDialog);
  if (!Vue.prototype.$aming) {
    Vue.prototype.$aming = {};
  }

  Vue.prototype.$aming.loginDialog = {
    open(options = {}) {
      if (!instance) {
        initInstance(Vue);
        document.body.appendChild(instance.$el);
        instance.$on("update:visible", (val) => {
          instance.visible = val;
        });
      }

      instance.visible = true;
      instance.$once("login", (tokens) => {
        if (options.onLogin) {
          options.onLogin(tokens);
        }
      });
      instance.$on("registerSuccess", () => {
        debugger;
        if (options.registerSuccess) {
          options.registerSuccess();
        }
      });
    },
    getVm() {
      return instance;
    },
    close() {
      if (instance) {
        instance.visible = false;
      }
    },
  };
};

export default LoginDialog;
