import { toFetchArgs, fetchCall } from "./fetch";
import { getDeviceCode } from "./common";

const BASE_URL = process.env.VUE_APP_HOST;
const OPEN_WECHAT_REDIRECT = `${BASE_URL}/api/v1/wechat/open/redirect`;

const TOKEN_CACHE_KEY = "_aming_core_user_token_";

export default class Client {
  constructor(opts = {}) {
    this.spuCode = opts?.spuCode || null;
    this.sellerCode = opts?.sellerCode || null;
    this.shopCode = opts?.shopCode || null;
    this.httpMethod = opts?.httpMethod || null;
    this.fetchMethod = opts?.fetchMethod || window.fetch;
    this.baseUrl = opts?.baseUrl || BASE_URL;
    this.tokenCacheKey = opts?.tokenCacheKey || TOKEN_CACHE_KEY;
    this.openWechatRedirect = opts?.openWechatRedirect || OPEN_WECHAT_REDIRECT;
  }

  /**
   * 克隆
   * @returns {Client}
   */
  clone() {
    return new Client({
      spuCode: this.spuCode,
      sellerCode: this.sellerCode,
      shopCode: this.shopCode,
      httpMethod: this.httpMethod,
      fetchMethod: this.fetchMethod,
      baseUrl: this.baseUrl,
      tokenCacheKey: this.tokenCacheKey,
      openWechatRedirect: this.openWechatRedirect,
    });
  }

  /**
   * 设置产品code
   * @param spuCode
   * @returns {Client}
   */
  setSpuCode(spuCode) {
    this.spuCode = spuCode;
    return this;
  }

  /**
   * 获取产品code
   * @returns {*}
   */
  getSpuCode() {
    return this.spuCode;
  }

  /**
   * 设置贴牌code
   * @param sellerCode
   * @returns {Client}
   */
  setSellerCode(sellerCode) {
    this.sellerCode = sellerCode;
    this.generateTokenCacheKey();
    return this;
  }

  /**
   * 获取贴牌code
   * @returns {*}
   */
  getSellerCode() {
    return this.sellerCode;
  }

  /**
   * 设置店铺名
   * @param shopCode
   * @returns {Client}
   */
  setShopCode(shopCode) {
    this.shopCode = shopCode;
    this.generateTokenCacheKey();
    return this;
  }

  /**
   * 生成token缓存key
   */
  generateTokenCacheKey() {
    this.tokenCacheKey = TOKEN_CACHE_KEY;

    if (this.sellerCode) {
      this.tokenCacheKey = `${this.tokenCacheKey}${this.sellerCode}_`;
    }

    if (this.shopCode) {
      this.tokenCacheKey = `${this.tokenCacheKey}${this.shopCode}_`;
    }
  }

  /**
   * 获取店铺名
   * @returns {*}
   */
  getShopCode() {
    return this.shopCode;
  }

  /**
   * 设置http请求方法
   * @param httpMethod
   * @returns {Client}
   */
  setHttpMethod(httpMethod) {
    this.httpMethod = httpMethod;
    return this;
  }

  /**
   * 设置fetch请求方法
   * @param fetchMethod
   * @returns {Client}
   */
  setFetchMethod(fetchMethod) {
    this.fetchMethod = fetchMethod;
    return this;
  }

  /**
   * 获取tokens
   * @returns {null|any}
   */
  async getTokens() {
    let tokens = this.shopCode
      ? sessionStorage.getItem(this.tokenCacheKey)
      : localStorage.getItem(this.tokenCacheKey);
    if (!tokens) {
      return null;
    }

    try {
      tokens = JSON.parse(tokens);
    } catch (e) {
      return null;
    }

    return tokens;
  }

  /**
   * 写入tokens
   * @param tokens
   */
  setTokens(tokens) {
    tokens = JSON.stringify(tokens);
    return this.shopCode
      ? sessionStorage.setItem(this.tokenCacheKey, tokens)
      : localStorage.setItem(this.tokenCacheKey, tokens);
  }

  /**
   * 删除token
   * @returns {Promise<void>}
   */
  async removeTokens() {
    sessionStorage.removeItem(this.tokenCacheKey);
    localStorage.removeItem(this.tokenCacheKey);
  }

  /**
   * 设置基础url
   * @param baseUrl
   * @returns {Client}
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
    return this;
  }

  /**
   * 获取基础url
   * @returns {*|string|string}
   */
  getBaseUrl() {
    return this.baseUrl;
  }

  /**
   * 设置微信开放平台回调地址
   * @param val
   * @returns {Client}
   */
  setOpenWechatRedirect(val) {
    this.openWechatRedirect = val;
    return this;
  }

  /**
   * 获取微信开放平台回调地址
   * @returns {*|string}
   */
  getOpenWechatRedirect() {
    return this.openWechatRedirect;
  }

  /**
   * 发起请求
   * @param options
   * @param noRefetch
   * @returns {Promise<*|*>}
   */
  async request(options, noRefetch) {
    if (typeof options === "string") {
      options = {
        url: options,
      };
    }

    const tokens = await this.getTokens();
    options = {
      baseUrl: this.getBaseUrl(),
      method: "GET",
      params: {},
      data: {},
      withCredentials: true,
      ...options,
      headers: {
        "Seller-Code": this.sellerCode || "",
        "Spu-Code": this.spuCode || "",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${tokens?.access_token || ""}`,
        // 仅Authorization（店铺名授权，只能用Authorization，不能用cookie内的token，有这个限制，后端不会取cookie内token，最终解决店铺和手机号授权共存）
        "Only-Authorization": this.shopCode ? "1" : "0",
        ...options.headers,
      },
    };

    options.method = options.method.toUpperCase();

    let result;
    try {
      if (this.httpMethod) {
        result = await this.httpMethod(options);
      } else {
        const fetchArgs = toFetchArgs(options);
        result = await fetchCall(
          this.fetchMethod,
          fetchArgs.url,
          fetchArgs.options
        );
      }
    } catch (e) {
      if (!noRefetch) {
        switch (e.code) {
          // 无效Token（前端重新登录授权）
          case 40100:
            // 店铺名登陆的，采用静默授权
            if (this.shopCode) {
              await this.loginByShopCode(this.shopCode);
              delete options.headers.Authorization;
              return this.request(options, true);
            }
            break;

          // Token过期（使用refresh token 重新授权）
          case 40101:
            await this.refreshToken();
            delete options.headers.Authorization;
            return this.request(options, true);
        }
      }

      throw e;
    }

    return result;
  }

  /**
   * 刷新token
   * @returns {Promise<*>}
   */
  async refreshToken() {
    const oldTokens = await this.getTokens();
    let tokens;
    try {
      tokens = await this.request(
        {
          url: "/api/v1/token/refresh",
          headers: {
            Authorization: `Bearer ${oldTokens?.refresh_token || ""}`,
          },
        },
        true
      );
    } catch (e) {
      if (this.shopCode) {
        switch (e.code) {
          // 无效Token
          case 40100:
          // Token过期
          case 40101:
            tokens = await this.loginByShopCode(this.shopCode);
            this.setTokens(tokens);
            return tokens;
        }
      }

      throw e;
    }

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 账号密码获取token
   * @param account
   * @param password
   * @returns {Promise<*>}
   */
  async loginByAccountAndPassword(account, password) {
    const tokens = await this.request({
      url: "/api/v1/tokens",
      method: "post",
      data: {
        account,
        password,
      },
    });

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 手机验证码获取token
   * @param phone
   * @param captcha
   * @returns {Promise<*>}
   */
  async loginByPhoneAndCaptcha(phone, captcha) {
    const tokens = await this.request({
      url: "/api/v1/tokens",
      method: "post",
      data: {
        phone,
        captcha,
      },
    });

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 店铺名获取token
   * @param shopCode
   * @returns {*}
   */
  async loginByShopCode(shopCode) {
    const tokens = await this.request({
      url: "/api/v1/tokens",
      method: "post",
      data: {
        shop_code: shopCode,
      },
    });

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 微信开发平台授权 获取token
   * @param appId
   * @param authCode
   * @param from
   * @returns {Promise<*>}
   */
  async loginByWechatOpen(appId, authCode, from = null) {
    const tokens = await this.request({
      url: "/api/v1/tokens",
      method: "post",
      data: {
        wechat_app_id: appId,
        wechat_auth_code: authCode,
        from,
      },
    });

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 微信公众号扫码 获取token
   * @param qrcodeId
   * @param from
   * @returns {Promise<*>}
   */
  async loginByWechatScan(qrcodeId, from = null) {
    const tokens = await this.request({
      url: "/api/v1/tokens",
      method: "post",
      data: {
        qrcode_id: qrcodeId,
        from,
      },
    });

    this.setTokens(tokens);
    return tokens;
  }

  /**
   * 退出登录
   * @returns {Promise<void>}
   */
  async logout() {
    try {
      await this.request({
        url: "/api/v1/token",
        method: "delete",
      });
      await this.removeTokens();
    } catch (e) {}
  }

  /**
   * 创建短信验证码
   * @param phone
   * @param scene
   * @returns {Promise<null|*>}
   */
  createSmsCaptcha(phone, scene) {
    return this.request({
      url: "/api/v1/captcha",
      method: "post",
      data: {
        phone,
        scene,
      },
    });
  }

  /**
   * 创建微信公众号二维码
   * @param scene
   * @returns {Promise<*>}
   */
  createWechatOfficialQrcode(scene) {
    return this.request({
      url: "/api/v1/wechat/official/qrcodes",
      method: "post",
      data: {
        scene,
      },
    });
  }

  /**
   * 获取微信公众号二维码（状态）
   * @param id
   * @returns {Promise<*>}
   */
  getWechatOfficialQrcode(id) {
    return this.request({
      url: `/api/v1/wechat/official/qrcodes/${id}`,
      method: "get",
    });
  }

  /**
   * 重置密码
   * @param phone
   * @param password
   * @param captcha
   * @returns {Promise<*>}
   */
  resetPassword(phone, password, captcha) {
    return this.request({
      url: `/api/v1/users/password`,
      method: "put",
      data: {
        phone,
        password,
        captcha,
      },
    });
  }

  /**
   * 修改密码
   * @param oldPassword
   * @param newPassword
   * @returns {Promise<*>}
   */
  updatePassword(oldPassword, newPassword) {
    return this.request({
      url: "/api/v1/user/password",
      method: "put",
      data: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    });
  }

  /**
   * 用户注册
   * @param phone
   * @param password
   * @param captcha
   * @param nickname
   * @returns {Promise<*>}
   */
  register(phone, password, captcha, nickname) {
    return this.request({
      url: "/api/v1/users",
      method: "post",
      data: {
        phone,
        password,
        captcha,
        nickname,
      },
    });
  }

  /**
   * 获取当前用户
   * @returns {*|Promise<null|*>}
   */
  async getCurrentUser() {
    const headers = {};
    const deviceCode = getDeviceCode();
    if (deviceCode) {
      headers["Device-Code"] = deviceCode;
    }

    const user = await this.request({
      url: "/api/v1/user",
      headers,
    });

    // 如果有token，写入token
    if (user.access_token && user.refresh_token) {
      this.setTokens({
        access_token: user.access_token,
        refresh_token: user.refresh_token,
      });
    }

    return user;
  }

  /**
   * 获取当前用户授权列表
   * @returns {Promise<*>}
   */
  getCurrentUserAuths() {
    return this.request({
      url: "/api/v1/user/auths",
    });
  }

  /**
   * 获取当前用户订单列表
   * @returns {Promise<*>}
   */
  getCurrentUserOrderPaginate(params) {
    return this.request({
      url: "/api/v1/user/orders",
      params,
    });
  }

  /**
   * 当前用户绑定微信
   * @param appId
   * @param authCode
   * @returns {Promise<*>}
   */
  currentUserBindWechat(appId, authCode) {
    return this.request({
      url: "/api/v1/user/bind-wechat",
      method: "post",
      data: {
        wechat_app_id: appId,
        wechat_auth_code: authCode,
      },
    });
  }

  /**
   * 获取商品列表
   * @returns {Promise<*>}
   */
  getSpus() {
    return this.request({
      url: "/api/v1/spus",
    });
  }

  /**
   * 获取七牛云上传token
   * @param source_type 类型：image-图片、video-视频
   * @returns {Promise<*>}
   */
  getQiniuUploadToken(source_type) {
    return this.request({
      url: `/api/v1/qiniu-upload-tokens`,
      method: "post",
      data: {
        source_type,
      },
    });
  }

  /**
   * 申请试用
   * @param authId
   * @returns {Promise<*>}
   */
  authApplyTrial(authId) {
    return this.request({
      url: `/api/v1/user/auths/${authId}/apply-trial`,
      method: "post",
    });
  }

  /**
   * 获取当前用户产品授权是否可扩容
   * @param spuCode
   * @returns {Promise<boolean>}
   */
  async getCurrentUserSpuAuthQuotaValid(spuCode) {
    if (!spuCode) {
      spuCode = this.getSpuCode();
    }

    let result;
    try {
      result = await this.request({
        url: `/api/v1/user/spus/${spuCode}/auth/quota-package-funs`,
      });
    } catch (e) {
      return false;
    }

    // 套餐必须有扩容功能 且 当前用户授权必须有效
    return result.data.length && result?.auth?.is_valid === true;
  }

  /**
   * 获取当前用户加群二维码
   * @param spuCode
   * @returns {Promise<*>}
   */
  getCurrentUserMarketWechatChat(spuCode = null) {
    if (!spuCode) {
      spuCode = this.getSpuCode();
    }

    return this.request({
      url: "/api/v1/user/market/wechat-chat",
      params: {
        spu_code: spuCode,
      },
    });
  }
}
