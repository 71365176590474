<template>
  <el-dialog
    :visible.sync="dialogVisible"
    class="am-simple-dialog"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "am-simple-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.am-simple-dialog::v-deep(.el-dialog__header) {
  padding: 0;
}

.am-simple-dialog::v-deep(.el-dialog__headerbtn) {
  right: -24px;
  top: 0;
}

.am-simple-dialog::v-deep(.el-dialog__close) {
  color: #fff;
  font-size: 20px;
}
</style>
