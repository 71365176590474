<template>
  <div>
    <el-tabs
      v-model="loginMode"
      :class="{ 'am-login__login_hidden-tabs-header': !login.captchaLogin }"
      :stretch="true"
    >
      <el-tab-pane label="密码登录" name="密码登录">
        <el-form
          ref="passwordForm"
          class="am-login__login-form"
          :model="passwordForm"
          :rules="passwordRules"
          @keyup.enter.native="onClickLogin()"
        >
          <el-form-item prop="account">
            <el-input
              v-model.trim="passwordForm.account"
              :placeholder="login.loginAccountPlaceholder"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model.trim="passwordForm.password"
              type="password"
              placeholder="请输入登录密码"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane
        v-if="login.captchaLogin"
        label="验证码登录"
        name="验证码登录"
      >
        <el-form
          ref="captchaForm"
          class="am-login__login-form"
          :model="captchaForm"
          :rules="captchaRules"
          @keyup.enter.native="onClickLogin()"
        >
          <el-form-item prop="phone">
            <el-input
              v-model.trim="captchaForm.phone"
              placeholder="请输入手机号"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="captcha">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <el-input
                v-model.trim="captchaForm.captcha"
                placeholder="请输入6位验证码"
              >
              </el-input>

              <captcha-button
                style="padding: 0 12px"
                :phone-method="getPhone"
                scene="登录"
                type="text"
                >点击获取
              </captcha-button>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <div class="am-login__login__tools">
      <el-link
        type="info"
        :underline="false"
        class="am-login__login__tools__link"
        @click="$emit('go', 'ResetPwd')"
        >忘记密码
      </el-link>
    </div>

    <el-button
      style="width: 100%; margin-top: 22px"
      type="primary"
      :loading="loading"
      @click="onClickLogin()"
      >登录
    </el-button>

    <div
      v-if="login.register"
      style="
        text-align: center;
        margin-top: 20px;
        color: #909399;
        line-height: 1.5;
      "
    >
      <span>还没有账号，</span>
      <el-link
        type="primary"
        :underline="false"
        class="am-login__login__tools__link"
        @click="$emit('go', 'Register')"
        >立即注册
      </el-link>
    </div>
  </div>
</template>

<script>
import CaptchaButton from "./captcha-button";
import { validatorPhone } from "@/utils/common";

export default {
  name: "login",
  components: { CaptchaButton },
  inject: ["login"],
  data() {
    this.passwordRules = {
      account: [
        { required: true, message: "请输入账号", trigger: ["blur"] },
        { validator: validatorPhone, trigger: ["blur"] },
      ],
      password: [{ required: true, message: "请输入密码", trigger: ["blur"] }],
    };

    this.captchaRules = {
      phone: [
        { required: true, message: "请输入手机号", trigger: ["blur"] },
        { validator: validatorPhone, trigger: ["blur"] },
      ],
      captcha: [{ required: true, message: "请输入验证码", trigger: ["blur"] }],
    };

    return {
      loginMode: "密码登录",
      passwordForm: {
        account: null,
        password: null,
      },
      captchaForm: {
        phone: null,
        captcha: null,
      },
      loading: false,
    };
  },
  methods: {
    getPhone() {
      return new Promise((resolve) => {
        this.$refs.captchaForm.validateField("phone", (error) => {
          if (error) {
            resolve(null);
          }

          resolve(this.captchaForm.phone);
        });
      });
    },

    onClickLogin() {
      const validate =
        this.loginMode === "密码登录"
          ? this.$refs.passwordForm.validate
          : this.$refs.captchaForm.validate;
      validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.loading = true;
        let result;
        try {
          result =
            this.loginMode === "密码登录"
              ? await this.$aming.client.loginByAccountAndPassword(
                  this.passwordForm.account,
                  this.passwordForm.password
                )
              : await this.$aming.client.loginByPhoneAndCaptcha(
                  this.captchaForm.phone,
                  this.captchaForm.captcha
                );
        } catch (e) {
          this.$message.error(e.message);
          return;
        } finally {
          this.loading = false;
        }

        this.$emit("login", result);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.am-login__login__icon {
  font-size: 16px;
  padding-left: 4px;
}

.am-login__login__tools {
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  color: #c0c4cc;
}

.am-login__login_hidden-tabs-header ::v-deep(.el-tabs__header) {
  display: none;
}

.am-login__login-form {
  &::v-deep(.el-form-item) {
    border-bottom: 1px solid #dcdfe6;
  }

  &::v-deep(.el-input__inner) {
    border: none;
    padding: 0;
  }
}

.am-login__login__tools__link {
  vertical-align: baseline;
}
</style>
