<template>
  <div
    :class="{
      'am-login__login': true,
      'am-login__login__scan-login': login.scanLogin,
    }"
  >
    <div
      v-if="login.scanLogin"
      :class="{
        'am-login__login-mode-wrapper': true,
        'am-login__login-mode-pc': isQrcode,
      }"
      :title="isQrcode ? '账号登录' : '扫码登录'"
      @click="isQrcode = !isQrcode"
    >
      <div class="am-login__login-mode-icon"></div>
    </div>

    <div class="am-login__login__header">
      {{ isQrcode ? "微信" : "账号" }}登录
    </div>

    <div class="am-login__login__body">
      <template v-if="!isQrcode">
        <login-account
          @login="$emit('login', $event)"
          @go="$emit('go', $event)"
        />
      </template>

      <template v-else>
        <login-qrcode
          @login="$emit('login', $event)"
          @go="$emit('go', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import LoginAccount from "./login-account";
import LoginQrcode from "./login-qrcode";

export default {
  name: "login-main",
  components: { LoginAccount, LoginQrcode },
  inject: ["login"],
  data() {
    return {
      isQrcode: this.login.defaultScanLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
.am-login__login {
  padding: 40px;

  .am-login__login-mode-wrapper {
    width: 85px;
    height: 85px;
    background: #f5f7fa;
    position: absolute;
    top: -43px;
    right: -43px;
    transform: rotate(45deg);
    cursor: pointer;
    overflow: hidden;

    .am-login__login-mode-icon {
      position: absolute;
      left: 30px;
      bottom: -12px;
      width: 24px;
      height: 24px;
      background: url("https://codecdn.mingshuce.com/core/components/img/qrcode-login-gray.png")
        no-repeat;
      background-size: contain;
      transform: rotate(-45deg);
      transition: 0.1s;
    }

    &:hover .am-login__login-mode-icon {
      background: url("https://codecdn.mingshuce.com/core/components/img/qrcode-login-primary.png")
        no-repeat;
      background-size: contain;
    }

    &.am-login__login-mode-pc .am-login__login-mode-icon {
      background: url("https://codecdn.mingshuce.com/core/components/img/pc-login-gray.png")
        no-repeat;
      background-size: contain;
    }

    &:hover.am-login__login-mode-pc .am-login__login-mode-icon {
      background: url("https://codecdn.mingshuce.com/core/components/img/pc-login-primary.png")
        no-repeat;
      background-size: contain;
    }
  }

  .am-login__login__header {
    font-size: 28px;
    color: #303133;
  }

  .am-login__login__body {
    padding-top: 40px;
  }
}
</style>
