<template>
  <div id="app">
    <div class="app-contain">
      <template v-if="hadInit">
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hadInit: false,
    };
  },
  mounted() {
    console.log(this.$aming);
    this.dataInit();
  },
  methods: {
    dataInit() {
      this.extCheck();
      document.querySelector(".spinner")?.remove();
      // setTimeout(() => {
      //   let sellerCode = this.$route?.params?.sellerCode;
      //   if (sellerCode) {
      //     this.$store.commit("setSellerCode", sellerCode);
      //   }
      // }, 100);
    },
    async extCheck() {
      try {
        this.$aming.login.register = false;
        this.$aming.login.showClose = false;
        let checkInfo = await this.$aming.auth.extCheck();
        console.log("checkInfo", checkInfo);
        this.$store.commit("setUser", checkInfo);
        this.hadInit = true;
      } catch (e) {
        console.log(e);
        // this.hadInit = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#app {
  background: url("@/assets/images/bg-body.png") no-repeat top center;
  background-size: 100%;
  .app-contain {
    width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
  }
}
</style>
