const state = {
  timer: null,
  loading: null,
  error: null,
  data: null,
  promises: [],
};

export default class Auth {
  constructor(Vue) {
    this.vm = Vue.prototype;
  }

  /**
   * 授权初始化
   * @param showTimeoutMessage
   * @returns {Promise<null>}
   */
  init(showTimeoutMessage = false) {
    return new Promise(async (resolve, reject) => {
      // 如果有数据，直接返回
      if (state.data) {
        resolve(state.data);
        return;
      }

      // 如果有错误，直接返回
      if (state.error) {
        reject(state.error);
        return;
      }

      // 添加到promises
      state.promises.push({
        resolve,
        reject,
      });

      // 不是待加载状态 直接返回（避免重复调用init，导致重复请求接口）
      if (state.loading !== null) {
        return;
      }

      state.loading = { close() {} };
      state.error = null;
      state.data = null;

      // 显示超时message
      if (showTimeoutMessage) {
        state.timer = setTimeout(() => {
          if (state.loading === false) {
            return;
          }

          state.loading = this.vm.$message({
            iconClass: "el-icon-loading",
            message: " 正在加载中…",
            center: true,
            duration: 0,
          });
        }, 1000);
      }

      // 获取用户信息
      try {
        state.data = await this.vm.$aming.client.getCurrentUser();
      } catch (e) {
        state.error = e;
        state.promises.forEach(({ reject }) => {
          reject(e);
        });
        state.promises = [];
        return;
      } finally {
        state.loading.close();
        state.loading = false;
        clearTimeout(state.timer);
      }

      state.promises.forEach(({ resolve }) => {
        resolve(state.data);
      });
      state.promises = [];
    });
  }

  /**
   * 插件授权校验
   * @param showResultMessage 是否显示结果
   * @returns {Promise<null>}
   */
  async extCheck(showResultMessage = true) {
    if (state.loading === null) {
      try {
        await this.init(showResultMessage);
      } catch (e) {}
    }
    if (state.loading) {
      if (showResultMessage) {
        this.vm.$message.warning("请勿点击过快");
      }

      throw new Error("请勿点击过快");
    }

    if (state.error) {
      if (!showResultMessage) {
        throw state.error;
      }
      // 登陆失效，重新登录
      if (
        [40100, 40101].includes(state.error.code) &&
        !this.vm.$aming.client.getShopCode()
      ) {
        this.vm.$aming.loginDialog.open({
          onLogin: function () {
            setTimeout(() => {
              location.reload();
            }, 500);
          },
        });
        throw state.error;
      }

      this.vm.$message.error(state.error.message);
      throw state.error;
    }

    if (state?.data?.current_spu?.need_bind_wechat === true) {
      if (showResultMessage) {
        this.vm.$aming.bindWechatDialog.open({
          wechatAppId: state.data.current_spu.wechat_app_id,
        });
      }

      throw new Error("请先绑定微信");
    }

    // if ((state?.data?.current_spu?.auth || null) === null) {
    //   if (showResultMessage) {
    //     this.vm.$message.error("无产品授权");
    //   }
    //
    //   throw new Error("无产品授权");
    // }

    // if (state?.data?.current_spu?.auth?.is_valid === false) {
    //   if (showResultMessage) {
    //     this.vm.$aming.renewDialog.open();
    //   }
    //
    //   throw new Error("产品授权已到期");
    // }

    return state.data;
  }

  /**
   * 重置授权
   */
  reset() {
    state.timer = null;
    state.loading = null;
    state.error = null;
    state.data = null;
    state.promises = [];
  }
}
