import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: 0,
    messageTip: true,
    // sellerCode: "",
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setMessageTip(state, data) {
      state.messageTip = data;
    },
    // setSellerCode(state, data) {
    //   state.sellerCode = data;
    // },
  },
});

export default store;
