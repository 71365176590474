import WxLogin from "@/utils/wx-login";

export default class OpenQrcode {
  constructor(eleId, appId, redirectUrl, callback) {
    this.eleId = eleId;
    this.appId = appId;
    this.redirectUrl = redirectUrl;
    this.callback = callback;
    this.handleCallback = (...args) => {
      this.handleOpenQrcode(...args);
    };
  }

  load() {
    window.addEventListener("message", this.handleCallback);

    const containerElement = window.document.createElement("div");
    containerElement.id = `${this.eleId}_container`;
    containerElement.style = "transform: scale(0.75) translateY(-66px);";

    const rootElement = window.document.getElementById(this.eleId);
    rootElement.style =
      "display: flex; justify-content: center; height: 225px; overflow: hidden; margin-top: -20px;";
    rootElement.appendChild(containerElement);

    new WxLogin({
      self_redirect: true,
      id: containerElement.id,
      appid: this.appId,
      scope: "snsapi_login",
      redirect_uri: encodeURI(this.redirectUrl),
      state: this.appId,
      style: "",
      href: "",
    });
  }

  handleOpenQrcode(e) {
    let result = e?.data;
    if (!result) {
      return;
    }

    const { type, code } = result;
    if (type !== "aming-core-wechat-open-redirect") {
      return;
    }

    this.destroy();
    if (!code) {
      this.callback(new Error("code不能为空"));
      return;
    }

    this.callback(null, code);
  }

  destroy() {
    window.removeEventListener("message", this.handleCallback);
    const rootElement = window.document.getElementById(this.eleId);
    if (rootElement && rootElement.firstChild) {
      rootElement.removeChild(rootElement.firstChild);
    }
  }
}
