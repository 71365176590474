<template>
  <el-button
    :loading="loading"
    :disabled="!!expire"
    :type="type"
    @click="onClick"
  >
    <template v-if="expire"> {{ expire }}秒后获取 </template>

    <template v-else>
      <slot></slot>
    </template>
  </el-button>
</template>

<script>
export default {
  name: "captcha-button",
  props: {
    scene: String,
    phoneMethod: Function,
    type: String,
  },
  data() {
    return {
      loading: false,
      expire: 0,
    };
  },
  methods: {
    async onClick() {
      if (this.expire) {
        return;
      }

      const phone = await this.phoneMethod();
      if (!phone) {
        return;
      }

      // 调用接口发送验证码
      this.loading = true;
      try {
        await this.$aming.client.createSmsCaptcha(phone, this.scene);
      } catch (e) {
        this.$message.error(e.message);
        return;
      } finally {
        this.loading = false;
      }

      // 创建定时器
      this.expire = 60;
      const timer = setInterval(() => {
        this.expire--;
        if (this.expire === 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
