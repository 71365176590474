<template>
  <div class="am-login__wrapper">
    <component :is="component" @login="onLogin" @go="component = $event" />
  </div>
</template>

<script>
import LoginMain from "./login-main";
import Register from "./register";
import ResetPwd from "./reset-pwd";

export default {
  name: "am-login",
  components: {
    LoginMain,
    Register,
    ResetPwd,
  },
  provide() {
    return {
      login: this,
    };
  },
  props: {
    // 是否可以扫码登陆
    scanLogin: {
      type: Boolean,
      default() {
        return this.$aming?.login?.scanLogin ?? true;
      },
    },
    // 是否默认使用扫码登录
    defaultScanLogin: {
      type: Boolean,
      default() {
        return this.$aming?.login?.defaultScanLogin ?? false;
      },
    },
    // 扫码登陆from参数(后端需要)
    scanLoginFrom: {
      type: String,
      default() {
        return this.$aming?.login?.scanLoginFrom ?? null;
      },
    },
    // 是否可以短信验证码登陆
    captchaLogin: {
      type: Boolean,
      default() {
        return this.$aming?.login?.captchaLogin ?? false;
      },
    },
    // 是否可以注册
    register: {
      type: Boolean,
      default() {
        return this.$aming?.login?.register ?? true;
      },
    },
    // 是否显示去登录
    hasGoLogin: {
      type: Boolean,
      default() {
        return this.$aming?.login?.hasGoLogin ?? true;
      },
    },
    // 是否显示注册协议
    registerAgreement: {
      type: Boolean,
      default() {
        return this.$aming?.login?.registerAgreement ?? true;
      },
    },
    // 注册协议品牌
    registerAgreementBrand: {
      type: String,
      default: "阿明查查",
    },
    // 登陆账号输入框占位文本
    loginAccountPlaceholder: {
      type: String,
      default() {
        return this.$aming?.login?.loginAccountPlaceholder ?? "请输入手机号";
      },
    },
    //   初始显示类型
    loginComponentType: {
      type: String,
      default() {
        return this.$aming?.login?.loginComponentType ?? "LoginMain";
      },
    },
  },
  data() {
    return {
      component: this.loginComponentType,
    };
  },
  created() {
    console.log(this);
  },
  methods: {
    onLogin(tokens) {
      this.$message.success("登录成功");
      this.$emit("login", tokens);
    },
  },
};
</script>

<style lang="scss" scoped>
.am-login__wrapper {
  position: relative;
  width: 440px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
