import "./element-ui-components.js";
import Vue from "vue";
import { Message } from "element-ui";
Vue.prototype.$message = Message;
import Login from "@/components/login/index.js";
import LoginDialog from "@/components/login-dialog/index.js";
import SimpleDialog from "@/components/simple-dialog/index.js";
import { getSellerCode } from "@/utils/tool.js";
import Client from "@/utils/client.js";
import Auth from "@/components/auth/index.js";

const components = [Login, LoginDialog, SimpleDialog];
const install = function (Vue, opts = {}) {
  Vue.prototype.$aming = {
    ...opts,
  };
  if (!Vue.prototype.$aming.client) {
    let params = {};
    let sellerCode = getSellerCode();
    if (sellerCode) {
      params.sellerCode = sellerCode;
    }
    Vue.prototype.$aming.client = new Client(params);
  }

  Vue.prototype.$aming.auth = new Auth(Vue);

  components.forEach((component) => {
    component.install(Vue);
  });
};

install(Vue, {
  login: {},
});
