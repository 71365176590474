<template>
  <div class="am-login__login">
    <div class="am-login__login__header">修改密码</div>

    <div class="am-login__login__body">
      <el-form
        ref="form"
        class="am-login__login-form"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="phone">
          <el-input
            v-model.trim="form.phone"
            placeholder="请输入手机号"
            :readonly="!!defaultPhone"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="captcha">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-input v-model.trim="form.captcha" placeholder="请输入6位验证码">
            </el-input>

            <captcha-button
              style="padding: 0 12px"
              :phone-method="getPhone"
              scene="重置密码"
              type="text"
              >点击获取
            </captcha-button>
          </div>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="form.password"
            type="password"
            placeholder="请输入登录密码"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <el-button
        style="width: 100%"
        type="primary"
        :loading="loading"
        @click="onClick()"
        >确认
      </el-button>

      <div v-if="!defaultPhone" class="am-login__login__tools">
        <div></div>
        <el-link
          type="info"
          :underline="false"
          class="am-login__login__tools__link"
          @click="$emit('go', 'LoginMain')"
          >返回登陆
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
import CaptchaButton from "./captcha-button";
import { validatorPhone } from "@/utils/common";

export default {
  name: "reset-pwd",
  components: { CaptchaButton },
  props: {
    defaultPhone: String,
  },
  data() {
    this.rules = {
      phone: [
        { required: true, message: "请输入手机号", trigger: ["blur"] },
        { validator: validatorPhone, trigger: ["blur"] },
      ],
      captcha: [{ required: true, message: "请输入验证码", trigger: ["blur"] }],
      password: [{ required: true, message: "请输入密码", trigger: ["blur"] }],
    };

    return {
      loading: false,
      form: {
        phone: this.defaultPhone || null,
        captcha: null,
        password: null,
      },
    };
  },
  methods: {
    getPhone() {
      return new Promise((resolve) => {
        this.$refs.form.validateField("phone", (error) => {
          if (error) {
            resolve(null);
          }

          resolve(this.form.phone);
        });
      });
    },

    // 点击重置密码
    onClick() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.loading = true;
        try {
          await this.$aming.client.resetPassword(
            this.form.phone,
            this.form.password,
            this.form.captcha
          );
        } catch (e) {
          this.$message.error(e.message);
          return;
        } finally {
          this.loading = false;
        }

        this.$message.success("修改成功");
        this.$emit("go", "LoginMain");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.am-login__login {
  position: relative;
  padding: 40px;

  .am-login__login__header {
    font-size: 28px;
    color: #303133;
  }

  .am-login__login__body {
    padding-top: 40px;
  }
}

.am-login__login__icon {
  font-size: 16px;
  padding-left: 4px;
}

.am-login__login__tools {
  margin-top: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c0c4cc;

  .am-login__login__tools__link {
    vertical-align: baseline;
  }
}

.am-login__login-form {
  &::v-deep(.el-form-item) {
    border-bottom: 1px solid #dcdfe6;
  }

  &::v-deep(.el-input__inner) {
    border: none;
    padding: 0;
  }
}
</style>
