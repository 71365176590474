/**
 * 转fetch参数
 * @param options
 * @returns {{options, url: string}}
 */
export function toFetchArgs(options) {
  options = { ...options };

  let url = `${options.baseUrl}${options.url}`;
  delete options.url;

  if (options.params) {
    url += Object.entries(options.params).reduce((prev, curr) => {
      prev += !prev ? "?" : "&";
      prev += `${curr[0]}=${curr?.[1] ?? ""}`;
      return prev;
    }, "");
    delete options.params;
  }

  if (Object.keys(options.data).length > 0) {
    options.body = JSON.stringify(options.data);
  }
  delete options.data;

  if (options.withCredentials) {
    options.credentials = "include";
  }
  delete options.withCredentials;

  return {
    url,
    options,
  };
}

/**
 * 执行fetch
 * @param method
 * @param url
 * @param options
 * @returns {Promise<*|null>}
 */
export async function fetchCall(method, url, options) {
  let response;
  try {
    response = await method(url, options);
  } catch (e) {
    throw new Error("请求失败，请刷新页面重试");
  }

  // 删除成功
  if (response.ok && response.status === 204) {
    return null;
  }

  let result;
  try {
    result = await response.json();
  } catch (e) {
    throw new Error("响应数据JSON错误");
  }

  if (response.ok) {
    return result;
  }

  const error = new Error(result?.message ?? "未知错误");
  error.code = result?.code ?? 999;

  throw error;
}
