<template>
  <div v-loading="loading">
    <el-result v-if="error" icon="warning" :subTitle="error.message" />

    <div v-else style="text-align: center; font-size: 14px; color: #c0c4cc">
      <el-image
        v-if="qrcode"
        :src="qrcode.url"
        style="width: 200px; height: 200px"
      />

      <div v-else id="amLoginOpenWechat"></div>

      <div v-if="login.register" style="color: #909399">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-image
            src="https://codecdn.mingshuce.com/core/components/img/wechat-login.png"
            style="width: 20px; height: 20px"
          />
          <span style="margin-left: 4px">微信扫码即可登录</span>
        </div>
        <div style="margin-top: 20px">
          <span>还没有账号，</span>
          <el-link
            type="primary"
            :underline="false"
            style="vertical-align: baseline"
            @click="$emit('go', 'Register')"
            >立即注册
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfficialQrcode from "@/utils/official-qrcode";
import OpenQrcode from "@/utils/open-qrcode";

export default {
  name: "login-qrcode",
  inject: ["login"],
  data() {
    this.timer = null;

    return {
      loading: false,
      error: null,
      // 公众号二维码
      qrcode: null,
      // 开放平台应用id
      openWechatAppid: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      const spuCode = this.$aming.client.getSpuCode();
      if (!spuCode) {
        this.loadOfficialQrcode();
        return;
      }

      // 获取产品关联的微信应用id
      this.loading = true;
      this.error = null;
      let spu;
      try {
        spu = await this.$aming.client.request({
          url: `/api/v1/spus/${spuCode}`,
        });
      } catch (e) {
        this.error = e;
        return;
      } finally {
        this.loading = false;
      }

      this.openWechatAppid = spu?.wechat_app_id || null;
      if (!this.openWechatAppid) {
        this.loadOfficialQrcode();
        return;
      }

      this.loadOpenQrcode();
    },

    // 加载公众号登录二维码
    async loadOfficialQrcode() {
      this.officialQrcode = new OfficialQrcode(
        this.$aming.client,
        "扫码登录",
        this.officialQrcodeCallback
      );

      this.loading = true;
      this.error = null;
      this.qrcode = null;
      try {
        this.qrcode = await this.officialQrcode.create();
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },

    // 公众号登录二维码扫码回调
    async officialQrcodeCallback(error, qrcode = null) {
      if (error) {
        this.error = error;
        return;
      }

      let result;
      try {
        result = await this.$aming.client.loginByWechatScan(
          qrcode.id,
          this.login.scanLoginFrom
        );
      } catch (e) {
        // 微信未绑定账号，注册登录后绑定
        if (e.code === 40200) {
          this.$message.warning(e.message);
          this.$emit("go", "Register");
          return;
        }

        this.error = e;
        return;
      }

      this.$emit("login", result);
    },

    // 加载开放平台登录二维码
    loadOpenQrcode() {
      this.openQrcode = new OpenQrcode(
        "amLoginOpenWechat",
        this.openWechatAppid,
        this.$aming.client.getOpenWechatRedirect(),
        this.openQrcodeCallback
      );

      this.openQrcode.load();
    },

    // 开放平台回调
    async openQrcodeCallback(error, code) {
      if (error) {
        this.error = error;
        return;
      }

      let result;
      try {
        result = await this.$aming.client.loginByWechatOpen(
          this.openWechatAppid,
          code,
          this.login.scanLoginFrom
        );
      } catch (e) {
        // 微信未绑定账号，注册登录后绑定
        if (e.code === 40200) {
          this.$message.warning(e.message);
          this.$emit("go", "Register");
          return;
        }
        this.error = e;
        return;
      }

      this.$emit("login", result);
    },
  },
  beforeDestroy() {
    if (this.officialQrcode) {
      this.officialQrcode.destroy();
    }

    if (this.openQrcode) {
      this.openQrcode.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.am-login__login__wechat-open-wrapper {
  display: flex;
  justify-content: center;
  height: 225px;
  overflow: hidden;
  margin-top: -20px;
}
</style>
