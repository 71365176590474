import Browser from "./browser";

/**
 * 验证手机号
 * @param rule
 * @param value
 * @param callback
 */
export function validatorPhone(rule, value, callback) {
  if (!/^1[3-9]\d{9}$/.test(value)) {
    callback(new Error("手机号码格式不正确"));
    return;
  }

  callback();
}

/**
 * 验证是否是登录错误
 * @param e
 * @returns {boolean}
 */
export function checkLoginError(e) {
  return [40100, 40101].includes(e.code);
}

/**
 * 分转元
 * @param fen
 * @returns {*}
 */
export function fenToYuan(fen) {
  if ((fen ?? null) === null) {
    return fen;
  }

  let num = fen;
  num = fen * 0.01;
  num += "";
  let reg =
    num.indexOf(".") > -1
      ? /(\d{1,3})(?=(?:\d{3})+\.)/g
      : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg, "$1");
  num = toDecimal2(num);
  return num;
}

export function toDecimal2(x) {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }

  f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
}

/**
 * 金额元转分
 * @param value
 * @returns {number|*}
 */
export function yuanToFen(value) {
  if ((value ?? null) === null) {
    return value;
  }

  let m = 0;
  let s1 = value.toString();
  let s2 = Number(100).toString();

  try {
    m += s1.split(".")[1].length;
  } catch (e) {}

  try {
    m += s2.split(".")[1].length;
  } catch (e) {}

  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

/**
 * 数组转数
 * @param data
 * @param parentId
 * @returns {*[]}
 */
export function arrayToTree(data, parentId = 0) {
  const tree = [];

  data.forEach((item) => {
    if (item.parent_id === parentId) {
      const children = arrayToTree(data, item.id);
      if (children.length) {
        item.children = children;
      }

      tree.push({
        ...item,
      });
    }
  });
  return tree;
}

/**
 * @param {Promise} promiser
 * @returns {Promise}
 */
export function to(promiser) {
  return promiser.then(
    (res) => [null, res],
    (err) => [err, null]
  );
}

/**
 *
 * @param {String} content
 */
export function setCopy(content) {
  const textarea = document.createElement("textarea");
  textarea.style.cssText = "position:absolute;top:-100000px;left:0";
  textarea.value = content;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("Copy");
  document.body.removeChild(textarea);
}

let deviceCode = null;
/**
 * 获取设备编码
 * @returns {Promise<null>}
 */
export function getDeviceCode() {
  if (deviceCode) {
    return deviceCode;
  }

  try {
    deviceCode = btoa(
      JSON.stringify({
        browser: new Browser().browser,
      })
    );
  } catch (e) {}

  return deviceCode;
}
